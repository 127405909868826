/* eslint-disable */

function getBrowser() {
  var ua = navigator.userAgent;
  var tem;
  var M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }

  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Edge", version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    name: M[0],
    version: +M[1]
  };
}

function isSupported(browser) {
  let supported = false;
  if (browser.name === "Chrome" && browser.version >= 60) {
    // Juillet 2017
    supported = true;
  } else if (browser.name === "Edge" && browser.version >= 14) {
    // Fevrier 2016
    supported = true;
  } else if (browser.name === "Firefox" && browser.version >= 50) {
    // Septembre 2016
    supported = true;
  } else if (browser.name === "Safari" && browser.version >= 6.1) {
    // Juin 2013
    supported = true;
  } else if (browser.name === "Opera" && browser.version >= 53) {
    // Mai 2018
    supported = true;
  }
  return supported;
}

if (!isSupported(getBrowser())) {
  var txt =
    "<p>Old navigator detected . This version is not supported. Please try with one of these browser instead:</p>" +
    "<img src='./img/supported-browsers.png' style='width:500px'></img>" +
    "<p>(Chrome > 60 | Firefox > 50 | Safari > 6.1 | Edge > 14)</p>";

  var ie = document.createElement("div");
  ie.setAttribute(
    "style",
    "width:100%;height:100%;zindex:9999;text-align:center; position: fixed; top:0px; left:0px;"
  );

  var label = document.createElement("label");
  label.innerHTML = txt;
  ie.appendChild(label);
  document.body.appendChild(ie);
}
